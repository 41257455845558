<template>
  <div class="Login">
    <div class="Login__info">
      <h1 v-if="!theme.loginWelcomeTextBlack">{{$t('login_welcome_text')}}</h1>
      <h1 v-if="theme.loginWelcomeTextBlack">{{theme.loginWelcomeTextBlack}}</h1>
      <h2 v-if="!theme.loginWelcomeTextColor && communityInfo.watlab" :style="{ color: theme.mainColor }">WAT Lab</h2>
      <h2 v-if="!theme.loginWelcomeTextColor && !communityInfo.watlab" :style="{ color: theme.mainColor }">Experience Lab</h2>
      <h2 v-if="theme.loginWelcomeTextColor" :style="{ color: theme.mainColor }">{{theme.loginWelcomeTextColor}}</h2>
      <div class="Login__info__text">
      <p>{{ $t('mobile_verification_title') }}</p>
      </div>
    </div>
    <div class="Login__form">
      <form>
        <div>
          <label>{{$t('mobile_verification_code')}}</label>
          <input type="text" v-model="code2fa">
        </div>
        <Button color="accent" :buttonType="'button'" :disabled="!formValid" @click="sendCode($event)">
          {{$t('mobile_verification_action')}}
          <Spinner v-show="loadingAuth" />
        </Button>
      </form>
      <p style="margin-top:10px;font-size:14px;cursor:pointer; color:var(--primary-color)" v-on:click="resendCode()">{{ $t('mobile_verification_resend') }}</p>
    </div>
    <form-errors :showErrors="error">{{$t('mobile_verification_error')}}</form-errors>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
  import {
    COMMUNITY_DISABLE_2FA,
    SEND_VALIDATION_CODE,
    RESEND_VALIDATION_CODE
  } from '@/store/user.module';
  import {
    COMMUNITY_FETCH_INFO,
  } from "@/store/community/info.module";
  import { mapGetters } from 'vuex';
  import store from '@/store';
  import Button from '~/components/Button';
  import FormErrors from '@/components/FormErrors.vue';
  import Spinner from '~/components/Spinner.vue';


  export default {
    name: '2FAPage',

    components: {
      Button,
      FormErrors,
      Spinner
    },

    data() {
      return {
        code2fa: '',
        error: false,
        loadingAuth: false
      };
    },

    computed: {
      ...mapGetters([
        'theme',
        'nextStep',
        'communityInfo',
        'isClient'
      ]),

      formValid() {
        return (
          this.code2fa.length > 3 && !this.loadingAuth
        );
      },
    },

    methods: {
      async resendCode(){
        await store.dispatch(RESEND_VALIDATION_CODE)
        .then((data) => {
          Swal.fire({
              title:this.$t('mobile_verification_resent'),
              icon:"success",
              customClass:{popup:"swal-customSuccess"}
            })
        })
      },
      async sendCode(){
        this.loadingAuth = true
        const data = {
          code2fa: this.code2fa
        }
        await store.dispatch(SEND_VALIDATION_CODE, data)
        .then((data) => {
          this.loadingAuth = false
          store.commit(COMMUNITY_DISABLE_2FA)
          if(this.isClient) this.$router.push( { name: "clientLogin", params: { origin, finishLogin:true } } );
          else this.$router.push( { name: "login", params: { origin, finishLogin:true } } );
        })
        .catch((error) => {
          this.error = true
          this.loadingAuth = false
        })
      }
    },
  };
</script>

<style lang="scss">
  .Login {
    width                 : 100%;


    @media only screen and ( max-width: 769px ) {
      width               : 100%;
      padding             : 1rem 0;
    }

    &__info {
      width               : 75%;
      display             : flex;
      flex-direction      : column;
      align-items         : flex-start;
      margin-left         : 60px;

      h1 {
        font-size         : 25px;
        font-family       : inherit;
        font-weight       : 700;
        margin-bottom     : 0;
        color             : #191919;
      }

      h2 {
        margin            : 0;
        margin-top        : -10px;
        padding           : 0 0 10px 0;
        border-bottom     : 1px solid #e9e9e9;
        font-size         : 40px;
        font-family       : inherit;
        font-weight       : 700;
      }

      p {
        color             : #797979;
        font-size         : 13px;
      }

      &__text {
        padding-top       : 10px;
        padding-bottom    : 10px;
      }
    }

    &__form {
      padding             : 20px 0 20px 60px;
      background          : #ededed;
      display             : flex;
      flex-direction      : column;
      align-items         : flex-start;

      form {
        display           : flex;
        align-items       : flex-end;
        flex-wrap         : wrap;
        margin            : 5px 0;

        .Button {
          margin-top      : 10px;
        }

        div {
          display         : flex;
          flex-direction  : column;
          margin-right    : 10px;

          label {
            font-size     : 12px;
            color         : #494949;
            font-weight   : bold;
          }

          input {
            padding       : 10px;
            margin-top    : 5px;
            border        : none;
            outline       : none;
            box-shadow    : 0px 0px 20px 0px rgba( 0, 0, 0, 0.1 );
            transition    : box-shadow 300ms;

            &:focus {
              box-shadow  : 0px 0px 20px 0px rgba( 0, 0, 0, 0.3 );
            }
          }
        }
      }
      a {
        color             : var( --primary-color );
        font-size         : 12px;

        &:hover {
          text-decoration : underline;
        }
      }
    }

    .button-icon{
      margin: auto auto auto 5px;
      font-size: 18px;
    }
  }

  @media only screen and ( max-width: 769px ) {
    .Login {
      &__info {
        margin            : 1rem auto;
      }
      &__form {
        padding           : 20px;

        form {
          flex-direction  : column;
          width           : 100%;

          .Button--Accent {
            margin-top    : 10px;
            width         : 100%;
            height        : 34px;
            font-size     : 16px;
          }

          div {
            width         : 100%;
            margin-right  : 0;

            input {
              margin      : 5px 0;
            }
          }
        }
      }
    }
  }
</style>
